
import React, { useMemo } from 'react';

import { inject, observer } from 'mobx-react'
import Router, { useRouter } from 'next/router'

import Navbar from '@components/Navbar'
import { HOME_PATH, LOGIN_BEFORE_PATHS, THEME, USER_PATHS } from '@constants/index'
import useClient from '@lib/hooks/useClient';
import { I18n } from '@utils/index';
import { LeftSide } from '@widget/loginRegister/leftSide';
import { Login } from '@widget/loginRegister/Login'
import { MainStructure } from '@widget/loginRegister/MainStructure'
import { Title } from '@widget/loginRegister/Title';

import styles from './index.module.less';

const loginJumpPath = () => {
  const referrer = Router.query?.referrer;
  if (!referrer) {
    return HOME_PATH
  }
  try {
    const res = new URL(referrer);
    return `${res.pathname}${res.search}`
  } catch (error) {
    return HOME_PATH
  }
}

export default inject(rootStore => ({
  userStore: rootStore.store.userStore,
  safetyStore: rootStore.store.getSafetyStore
}))(
  observer(props => {
    const router = useRouter()
    const isPc = useClient()

    const theme = useMemo(() => {
      const type = router.query?.type
      if (!type) {
        return isPc ? THEME.DARK : THEME.HIGHLIGHT
      }
      if (type === 'invitationRebate') {
        return THEME.HIGHLIGHT
      }
      return THEME.DARK
    }, [router, isPc]);

    const cb = () => {
      const { socialUserInfo } = props.userStore
      if (!socialUserInfo || !socialUserInfo.avatar || !socialUserInfo.nickName) {
        router.push({
          pathname: USER_PATHS.USERINFO
        })
      } else {
        const path = loginJumpPath();
        router.push(path)
        // loginIsBack() ? router.back() : router.push({ pathname: HOME_PATH })
      }
    }

    const queryType = router.query?.type ? `?type=${router.query?.type}` : ''

    const main = <div className={styles.content}>
        <Title
          title={I18n.t('Kikitrade.Account.Login')}
          subTitle={I18n.t('jAxyJeO6')}
          link={`${LOGIN_BEFORE_PATHS.REGISTER}${queryType}`}
          jumpText={I18n.t('Sign.up.now')}
        />
      <Login oAuthList= {['Apple', 'Facebook', 'Google']} mode='redirect' cb ={cb} />
  </div>

    return (
      <div className={styles.container}>
        <Navbar theme={theme} type='1' />
        {<MainStructure componentLeft={<LeftSide type={router.query?.type} />} componentRight={main} />}
      </div>
    )
  })
)
